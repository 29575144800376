import { ReactNode } from "react";
import img from "../assets/images/placeholder-image.png";
import pdf from "../assets/images/placeholder-pdf.svg";
import { CommonStatus } from "../types";
import { capitalize } from "../utils/string";

// placeholder
export const IMG = img;
export const PDF = pdf;
export const dateFormat = "YYYY-MM-DD";

export const selectSearchOption = (
  input: string,
  option?: { label: string; value: string | number }
) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

export const addKeyInArray = (data: any) => {
  return data?.map((el: object & { _id?: string }, idx: number) => ({
    ...el,
    key: el?._id || `${idx}`,
  }));
};

export const ArrayOption = (
  data: any[],
  label: string,
  value: string,
  ctz?: boolean
) => {
  return data.map((el) => ({
    label: ctz ? capitalize(el[label]) : el[label],
    value: el[value] || null,
  }));
};

export const changeStatus = (val: string) => {
  switch (val) {
    case "Save as Draft":
      return "Draft";

    default:
      return val;
  }
};

export const StringArrayOption = (data: string[]) => {
  return data.map((el) => ({
    label: changeStatus(el),
    value: el,
  }));
};

export const addKeyInArrayRevereLoginLog = (data: any) => {
  return data
    ?.slice()
    .reverse()
    .map((el: { _id?: string }, idx: number) => ({
      ...el,
      key: el?._id || `${idx}`,
    }));
};

export const returnStringStatus = (val: boolean) => {
  return (val ? "Active" : "Inactive") as CommonStatus;
};

export const returnBooleanStatus = (val: CommonStatus | undefined) => {
  return (val === undefined ? undefined : val === "Active" ? true : false) as
    | boolean
    | undefined;
};

export const checkEmptyValue = (val?: string) => {
  return val && val !== "N/A" ? val : undefined;
};

export const returnNAForEmpty = (val: ReactNode) => {
  return val || "N/A";
};

export const commonStatusClass = {
  Active: "status-done",
  Inactive: "status-danger",
};
