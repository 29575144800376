import { createSlice } from "@reduxjs/toolkit";

import { AreaInitialStatus } from "../../../types/redux/geo/area";
import {
  createAreaAsync,
  deleteAreaAsync,
  getAllAreaAsync,
  getAllAreaByFilterAsync,
  singAreaAsync,
  updateAreaAsync,
} from "./AreaApi";

const initialState: AreaInitialStatus<any, any> = {
  loading: false,
  manageLoading: false,
  area: [],
  areaByFilter: [],
  singleAreaData: null,
  countArea: 0,
};

const areaSlice = createSlice({
  name: "admin-Area",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create
    builder
      .addCase(createAreaAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(createAreaAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(createAreaAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // get all
    builder
      .addCase(getAllAreaAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllAreaAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.area = action.payload.data;
        state.countArea = action.payload.length;
      })
      .addCase(getAllAreaAsync.rejected, (state) => {
        state.loading = false;
        state.area = [];
        state.countArea = 0;
      });

    // get all by filter
    builder
      .addCase(getAllAreaByFilterAsync.pending, (state) => {})
      .addCase(getAllAreaByFilterAsync.fulfilled, (state, action) => {
        state.area = action.payload.data;
      })
      .addCase(getAllAreaByFilterAsync.rejected, (state) => {
        state.area = [];
        state.countArea = 0;
      });

    // update
    builder
      .addCase(updateAreaAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(updateAreaAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(updateAreaAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // delete
    builder
      .addCase(deleteAreaAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(deleteAreaAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(deleteAreaAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // single
    builder
      .addCase(singAreaAsync.pending, (state) => {
        state.manageLoading = true;
        state.singleAreaData = null;
      })
      .addCase(singAreaAsync.fulfilled, (state, action) => {
        state.manageLoading = false;
        state.singleAreaData = action.payload;
      })
      .addCase(singAreaAsync.rejected, (state) => {
        state.manageLoading = false;
        state.singleAreaData = null;
      });
  },
});

export default areaSlice;
