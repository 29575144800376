import { createSlice } from "@reduxjs/toolkit";
import {
  DealerData,
  DealerInitialStatus,
  GetDealerData,
  GetSingleDealerData,
} from "../../../types/redux/dealers/dealers";
import {
  createDealerAsync,
  getAllDealerAsync,
  singleDealerAsync,
  updateDealerAsync,
  updateDealerStatusAsync,
} from "./DealersApi";

const initialState: DealerInitialStatus<
  GetDealerData,
  GetSingleDealerData,
  DealerData
> = {
  loading: false,
  manageLoading: false,
  viewLoading: false,
  dealers: [],
  singleDealerData: null,
  countDealer: 0,
  dealerFormData: null,
};

const dealerSlice = createSlice({
  name: "admin-dealer",
  initialState,
  reducers: {
    updateDealerFormData(state, action) {
      state.dealerFormData = action.payload;
    },
  },
  extraReducers: (builder) => {
    // create a dealer
    builder
      .addCase(createDealerAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(createDealerAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(createDealerAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // get all dealer
    builder
      .addCase(getAllDealerAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllDealerAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.dealers = action.payload.data;
        state.countDealer = action.payload.length;
      })
      .addCase(getAllDealerAsync.rejected, (state) => {
        state.loading = false;
        state.dealers = [];
        state.countDealer = 0;
      });

    // update a dealer
    builder
      .addCase(updateDealerAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(updateDealerAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(updateDealerAsync.rejected, (state) => {
        state.manageLoading = false;
      });
    // update a dealer status
    builder
      .addCase(updateDealerStatusAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(updateDealerStatusAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(updateDealerStatusAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // get single dealer
    builder
      .addCase(singleDealerAsync.pending, (state) => {
        state.viewLoading = true;
        state.singleDealerData = null;
      })
      .addCase(singleDealerAsync.fulfilled, (state, action) => {
        state.viewLoading = false;
        state.singleDealerData = action.payload;
      })
      .addCase(singleDealerAsync.rejected, (state) => {
        state.viewLoading = false;
        state.singleDealerData = null;
      });
  },
});

export const { updateDealerFormData } = dealerSlice.actions;
export default dealerSlice;
