export default function BankIcon({ color = "currentColor" }) {
  return (
    <svg
      width="24"
      height="19"
      viewBox="0 0 24 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.50049 17.5H22.5005M3.00049 14.5H21.0005M18.7505 7V14.5M14.2505 7V14.5M9.75049 7V14.5M5.25049 7V14.5M2.25045 7H21.7504L12.0004 1L2.25045 7Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
