import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
} from "../../../types/redux";
import { OrdersData, OrdersDataParams } from "../../../types/redux/order/order";

// get all
export const getAllOrderAsync = createAsyncThunk<
  GetCommonData<OrdersData>,
  Params<OrdersDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-order/getAllOrder", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.order}/data`, {
      params,
    });

    return {
      data: data.data?.orders,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get order data fail");
    return rejectWithValue(error);
  }
});

// single
export const singleOrderDataAsync = createAsyncThunk<
  any,
  Params<GetSingleDataParams>
>("admin-order/singleOrderData", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(
      `${BASE_API.order}/order-details/${params?.id}`
    );

    return data.data as any;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Single order data get fail");
    return rejectWithValue(error);
  }
});
