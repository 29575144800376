export const removeEmpty = (arr: string[] = []) => {
  return arr.filter((el) => el);
};

export const findObjFromArr = (arr: any[] = [], name: string, item: string) => {
  return arr?.find((el) => el?.[name] === item);
};

export const stringToArray = (text: string, splitText: string = " ") => {
  return removeEmpty(text.split(splitText));
};

export const extension = (url: string) => {
  const file = stringToArray(url, ".");
  return file?.[file?.length - 1];
};

export const activePathString = (
  path: string,
  splitText: string = "/",
  isDynamic?: boolean,
  isSubMenu?: boolean
) => {
  const pathArray = stringToArray(path, splitText);

  // return pathArray
  //   .slice(1, (isSubMenu ? 3 : 2) - (isDynamic ? 1 : 0))
  //   .join("/");

  return pathArray.slice(1, (isSubMenu ? 3 : 2) - 0).join("/");
};

export const pathString = (path: string, splitText: string = "/") => {
  return stringToArray(path, splitText).slice(1, 3).join("/");
};

export const notificationDateFilter = (notificationList: any[]) => {
  const output: any[] = [];
  let lastDate = "";

  notificationList?.forEach((notification) => {
    const currentDate = notification?.createdAt?.split("T")[0];
    if (currentDate !== lastDate) {
      output.push({ showDate: currentDate });
      lastDate = currentDate;
    }
    output.push(notification);
  });

  return output;
};
