import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import AXIOS from "../../../../helpers/api";
import { BASE_API } from "../../../../helpers/apiUrl";
import { toast } from "../../../../helpers/toast";
import {
  CommonOthers,
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
  PostRequest,
} from "../../../../types/redux";
import {
  CreateMenuData,
  GetMenuSingleData,
  MenuData,
  MenuDataParams,
} from "../../../../types/redux/user/menu";

// create
export const createMenuAsync = createAsyncThunk<
  null,
  PostRequest<CreateMenuData, CommonOthers>
>(
  "admin-user/createMenu",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.menu, data);

      reRender(true);
      toast("success", "Menu create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Menu create fail");
      return rejectWithValue(error);
    }
  }
);

// get all
export const getAllMenuAsync = createAsyncThunk<
  GetCommonData<MenuData>,
  Params<MenuDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-user/getAllMenu", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.menu}/data`, {
      params,
    });
    return {
      data: data.data?.menus,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get Menu fail");
    return rejectWithValue(error);
  }
});

// get single Menu
export const singMenuAsync = createAsyncThunk<any, Params<GetSingleDataParams>>(
  "admin-user/singMenuData",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.menu}/${params?.id}`);

      return data.data?.menus as any;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Single Menu data get fail");
      return rejectWithValue(error);
    }
  }
);

// update
export const updateMenuAsync = createAsyncThunk<
  null,
  PostRequest<MenuData, GetSingleDataParams>
>(
  "admin-user/updateMenu",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.menu}/${id}`, data);

      reRender && reRender(true);
      toast("success", "Menu update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Menu update fail");
      return rejectWithValue(error);
    }
  }
);

// delete
export const deleteMenuAsync = createAsyncThunk<
  null,
  Params<GetMenuSingleData>
>("admin-user/deleteMenu", async ({ params }, { rejectWithValue }) => {
  try {
    await AXIOS.delete(`${BASE_API.menu}/${params?.id}`);

    params?.reRender && params?.reRender(true);
    toast("success", "Menu delete successfully");
    return null;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", "Menu delete fail");
    return rejectWithValue(error);
  }
});
