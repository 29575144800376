import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../../helpers/api";
import { BASE_API } from "../../../../helpers/apiUrl";
import { toast } from "../../../../helpers/toast";
import {
  CommonOthers,
  CommonParams,
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
  PostRequest,
} from "../../../../types/redux";
import { ChequeData } from "../../../../types/redux/collateral/cheque";

// create
export const createChequeAsync = createAsyncThunk<
  null,
  PostRequest<ChequeData, CommonOthers>
>(
  "admin-cheque/createCheque",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.cheque, data);

      reRender(true);
      toast("success", "Cheque create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Cheque create fail");
      return rejectWithValue(error);
    }
  }
);

// get all
export const getAllChequeAsync = createAsyncThunk<
  GetCommonData<ChequeData>,
  Params<CommonParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-cheque/getAllCheque", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.cheque}/data`, {
      params,
    });

    return {
      data: data.data,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get Cheque fail");
    return rejectWithValue(error);
  }
});

// get all by filter
export const getAllChequeByFilterAsync = createAsyncThunk<
  GetCommonData<ChequeData>,
  Params<any>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-cheque/getAllChequeByFilter",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.cheque}/data`, {
        params,
      });

      return {
        data: data.data,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get Cheque fail");
      return rejectWithValue(error);
    }
  }
);

// update
export const updateChequeAsync = createAsyncThunk<
  null,
  PostRequest<ChequeData, GetSingleDataParams>
>(
  "admin-cheque/updateCheque",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.cheque}/${id}`, data);

      reRender && reRender(true);
      toast("success", "Cheque update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Cheque update fail");
      return rejectWithValue(error);
    }
  }
);

// delete
export const deleteChequeAsync = createAsyncThunk<
  null,
  Params<GetSingleDataParams>
>("admin-cheque/deleteCheque", async ({ params }, { rejectWithValue }) => {
  try {
    await AXIOS.delete(`${BASE_API.cheque}/${params?.id}`);

    params?.reRender && params?.reRender(true);
    toast("success", "Cheque delete successfully");
    return null;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", "Cheque delete fail");
    return rejectWithValue(error);
  }
});

// single
export const singChequeAsync = createAsyncThunk<
  any,
  Params<GetSingleDataParams>
>("admin-cheque/singChequeData", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.cheque}/${params?.id}`);

    return data.data as any;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Single Cheque data get fail");
    return rejectWithValue(error);
  }
});
