import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import AXIOS from "../../../../helpers/api";
import { BASE_API } from "../../../../helpers/apiUrl";
import { toast } from "../../../../helpers/toast";
import {
  CommonOthers,
  ErrorMessage,
  GetCommonData,
  Params,
  PostRequest,
} from "../../../../types/redux";
import { MenuData } from "../../../../types/redux/user/menu";
import {
  GetAllRoleParams,
  GetRolePermissionData,
  GetRolePermissionSingleData,
} from "../../../../types/redux/user/rolePermission";

// create
export const createRolePermissionAsync = createAsyncThunk<
  null,
  PostRequest<GetRolePermissionData, CommonOthers>
>(
  "admin-user/createRolePermission",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(`${BASE_API.role}/create-role`, data);

      reRender(true);
      toast("success", "Role create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Role permission create fail");
      return rejectWithValue(error);
    }
  }
);

// get all
export const getAllRolePermissionAsync = createAsyncThunk<
  GetCommonData<GetRolePermissionData>,
  Params<GetAllRoleParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-user/getAllRolePermission",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.role}/data`, {
        params,
      });

      return {
        data: data.data?.roles,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "get all role permission fail");
      return rejectWithValue(error);
    }
  }
);

// get all
export const getPermissionAsync = createAsyncThunk<
  GetCommonData<GetRolePermissionData>,
  undefined,
  {
    rejectValue: ErrorMessage;
  }
>("admin-user/getPermission", async (_, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.rolePermission);

    return {
      data: data.data?.roles,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "get role permission fail");
    return rejectWithValue(error);
  }
});

// get single
export const singleAllRolePermissionAsync = createAsyncThunk<
  any,
  Params<GetRolePermissionSingleData>
>(
  "admin-user/singleAllRolePermission",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.role}/${params?.id}`);

      return data.data?.role as any;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Single role permission data get fail");
      return rejectWithValue(error);
    }
  }
);

// get all by filter
export const getAllRolePermissionByFilterAsync = createAsyncThunk<
  GetCommonData<GetRolePermissionData>,
  Params<any>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-user/getAllRolePermissionByFilter",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.rolePermission}/data`, {
        params,
      });

      return {
        data: data.data?.roles,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get role permission fail");
      return rejectWithValue(error);
    }
  }
);

// update
export const updateRolePermissionAsync = createAsyncThunk<
  null,
  PostRequest<MenuData, GetRolePermissionSingleData>
>(
  "admin-user/updateRolePermission",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.role}/${id}`, data);

      reRender && reRender(true);
      toast("success", "Role permission update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Role permission update fail");
      return rejectWithValue(error);
    }
  }
);

// delete
export const deleteRolePermissionAsync = createAsyncThunk<
  null,
  Params<GetRolePermissionSingleData>
>(
  "admin-user/deleteRolePermission",
  async ({ params }, { rejectWithValue }) => {
    try {
      await AXIOS.delete(`${BASE_API.rolePermission}/${params?.id}`);

      params?.reRender && params?.reRender(true);
      toast("success", "role permission delete successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", "role permission delete fail");
      return rejectWithValue(error);
    }
  }
);
