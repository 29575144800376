import { createSlice } from "@reduxjs/toolkit";

import { PoliceStationInitialStatus } from "../../../types/redux/geo/policeStation";
import {
  createPoliceStationAsync,
  deletePoliceStationAsync,
  getAllPoliceStationAsync,
  getAllPoliceStationByFilterAsync,
  singPoliceStationAsync,
  updatePoliceStationAsync,
} from "./PoliceStationApi";

const initialState: PoliceStationInitialStatus<any, any> = {
  loading: false,
  manageLoading: false,
  policeStation: [],
  policeStationByFilter: [],
  singlePoliceStationData: null,
  countPoliceStation: 0,
};

const policeStationSlice = createSlice({
  name: "admin-PoliceStation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create
    builder
      .addCase(createPoliceStationAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(createPoliceStationAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(createPoliceStationAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // get all
    builder
      .addCase(getAllPoliceStationAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllPoliceStationAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.policeStation = action.payload.data;
        state.countPoliceStation = action.payload.length;
      })
      .addCase(getAllPoliceStationAsync.rejected, (state) => {
        state.loading = false;
        state.policeStation = [];
        state.countPoliceStation = 0;
      });

    // get all by filter
    builder
      .addCase(getAllPoliceStationByFilterAsync.pending, (state) => {})
      .addCase(getAllPoliceStationByFilterAsync.fulfilled, (state, action) => {
        state.policeStation = action.payload.data;
      })
      .addCase(getAllPoliceStationByFilterAsync.rejected, (state) => {
        state.policeStation = [];
        state.countPoliceStation = 0;
      });

    // update
    builder
      .addCase(updatePoliceStationAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(updatePoliceStationAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(updatePoliceStationAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // delete
    builder
      .addCase(deletePoliceStationAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(deletePoliceStationAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(deletePoliceStationAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // single
    builder
      .addCase(singPoliceStationAsync.pending, (state) => {
        state.manageLoading = true;
        state.singlePoliceStationData = null;
      })
      .addCase(singPoliceStationAsync.fulfilled, (state, action) => {
        state.manageLoading = false;
        state.singlePoliceStationData = action.payload;
      })
      .addCase(singPoliceStationAsync.rejected, (state) => {
        state.manageLoading = false;
        state.singlePoliceStationData = null;
      });
  },
});

export default policeStationSlice;
