import { createSlice } from "@reduxjs/toolkit";
import { CreateInitialStatus } from "../../../../types/redux/user/createUser";
import {
  createUserAsync,
  getAllUserAsync,
  singleUserAsync,
} from "./CreateUserApi";

const initialState: CreateInitialStatus<any, any> = {
  loading: false,
  manageLoading: false,
  singleLoading: false,
  users: [],
  singleUser: null,
  countUsers: 0,
};

const createUserSlice = createSlice({
  name: "create-user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create user
    builder
      .addCase(createUserAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(createUserAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(createUserAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // get all users
    builder
      .addCase(getAllUserAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllUserAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload?.data;
        state.countUsers = action.payload.length;
      })
      .addCase(getAllUserAsync.rejected, (state) => {
        state.loading = false;
        state.users = [];
        state.countUsers = 0;
      });

    // get single user
    builder
      .addCase(singleUserAsync.pending, (state) => {
        state.singleLoading = true;
        state.singleUser = null;
      })
      .addCase(singleUserAsync.fulfilled, (state, action) => {
        state.singleLoading = false;
        state.singleUser = action.payload;
      })
      .addCase(singleUserAsync.rejected, (state) => {
        state.singleLoading = false;
        state.singleUser = null;
      });
  },
});

export default createUserSlice;
