import { createSlice } from "@reduxjs/toolkit";
import {
  OrderInitialStatus,
  OrdersData,
} from "../../../types/redux/order/order";
import { getAllOrderAsync, singleOrderDataAsync } from "./OrderApi";

const initialState: OrderInitialStatus<OrdersData, any> = {
  loading: false,
  manageLoading: false,
  order: [],
  singleOrderData: null,
  countOrder: 0,
};

const orderSlice = createSlice({
  name: "admin-order",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get all
    builder
      .addCase(getAllOrderAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllOrderAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.order = action.payload.data;
        state.countOrder = action.payload.length;
      })
      .addCase(getAllOrderAsync.rejected, (state) => {
        state.loading = false;
        state.order = [];
        state.countOrder = 0;
      });

    // single
    builder
      .addCase(singleOrderDataAsync.pending, (state) => {
        state.manageLoading = true;
        state.singleOrderData = null;
      })
      .addCase(singleOrderDataAsync.fulfilled, (state, action) => {
        state.manageLoading = false;
        state.singleOrderData = action.payload;
      })
      .addCase(singleOrderDataAsync.rejected, (state) => {
        state.manageLoading = false;
        state.singleOrderData = null;
      });
  },
});

export default orderSlice;
