import { createSlice } from "@reduxjs/toolkit";
import {
  BankListData,
  BankListInitialStatus,
} from "../../../types/redux/bank/bankList";
import {
  createBankListAsync,
  deleteBankListAsync,
  getAllBankListAsync,
  getAllBankListByFilterAsync,
  singBankListAsync,
  updateBankListAsync,
} from "./BankListApi";

const initialState: BankListInitialStatus<BankListData, any> = {
  loading: false,
  manageLoading: false,
  banks: [],
  bankListByFilter: [],
  singleBankListData: null,
  countBankList: 0,
};

const bankListSlice = createSlice({
  name: "admin-bank",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create
    builder
      .addCase(createBankListAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(createBankListAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(createBankListAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // get all
    builder
      .addCase(getAllBankListAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllBankListAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.banks = action.payload.data;
        state.countBankList = action.payload.length;
      })
      .addCase(getAllBankListAsync.rejected, (state) => {
        state.loading = false;
        state.banks = [];
        state.countBankList = 0;
      });

    // get all by filter
    builder
      .addCase(getAllBankListByFilterAsync.pending, (state) => {})
      .addCase(getAllBankListByFilterAsync.fulfilled, (state, action) => {
        state.banks = action.payload.data;
      })
      .addCase(getAllBankListByFilterAsync.rejected, (state) => {
        state.banks = [];
        state.countBankList = 0;
      });

    // update
    builder
      .addCase(updateBankListAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(updateBankListAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(updateBankListAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // delete
    builder
      .addCase(deleteBankListAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(deleteBankListAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(deleteBankListAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // single
    builder
      .addCase(singBankListAsync.pending, (state) => {
        state.manageLoading = true;
        state.singleBankListData = null;
      })
      .addCase(singBankListAsync.fulfilled, (state, action) => {
        state.manageLoading = false;
        state.singleBankListData = action.payload;
      })
      .addCase(singBankListAsync.rejected, (state) => {
        state.manageLoading = false;
        state.singleBankListData = null;
      });
  },
});

export default bankListSlice;
