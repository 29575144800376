import { createSlice } from "@reduxjs/toolkit";
import { TerritoryInitialStatus } from "../../../types/redux/geo/territory";
import {
  createTerritoryAsync,
  deleteTerritoryAsync,
  getAllTerritoryAsync,
  getAllTerritoryByFilterAsync,
  singleTerritoryAsync,
  updateTerritoryAsync,
} from "./TerritoryApi";

const initialState: TerritoryInitialStatus<any, any> = {
  loading: false,
  manageLoading: false,
  territory: [],
  territoryByFilter: [],
  singleTerritoryData: null,
  countTerritory: 0,
};

const territorySlice = createSlice({
  name: "admin-Territory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create
    builder
      .addCase(createTerritoryAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(createTerritoryAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(createTerritoryAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // get all
    builder
      .addCase(getAllTerritoryAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllTerritoryAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.territory = action.payload.data;
        state.countTerritory = action.payload.length;
      })
      .addCase(getAllTerritoryAsync.rejected, (state) => {
        state.loading = false;
        state.territory = [];
        state.countTerritory = 0;
      });

    // get all by filter
    builder
      .addCase(getAllTerritoryByFilterAsync.pending, (state) => {})
      .addCase(getAllTerritoryByFilterAsync.fulfilled, (state, action) => {
        state.territory = action.payload.data;
      })
      .addCase(getAllTerritoryByFilterAsync.rejected, (state) => {
        state.territory = [];
        state.countTerritory = 0;
      });

    // update
    builder
      .addCase(updateTerritoryAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(updateTerritoryAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(updateTerritoryAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // delete
    builder
      .addCase(deleteTerritoryAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(deleteTerritoryAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(deleteTerritoryAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // single
    builder
      .addCase(singleTerritoryAsync.pending, (state) => {
        state.manageLoading = true;
        state.singleTerritoryData = null;
      })
      .addCase(singleTerritoryAsync.fulfilled, (state, action) => {
        state.manageLoading = false;
        state.singleTerritoryData = action.payload;
      })
      .addCase(singleTerritoryAsync.rejected, (state) => {
        state.manageLoading = false;
        state.singleTerritoryData = null;
      });
  },
});

export default territorySlice;
