import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CommonOthers,
  CommonParams,
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
  PostRequest,
} from "../../../types/redux";
import { DivisionData } from "../../../types/redux/geo/division";

// create
export const createDivisionAsync = createAsyncThunk<
  null,
  PostRequest<Omit<DivisionData, "_id">, CommonOthers>
>(
  "admin-geo/createDivision",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.division, data);

      reRender(true);
      toast("success", "Division create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Division create fail");
      return rejectWithValue(error);
    }
  }
);

// get all
export const getAllDivisionAsync = createAsyncThunk<
  GetCommonData<DivisionData>,
  Params<CommonParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-geo/getAllDivision", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.division}/data`, {
      params,
    });

    return {
      data: data.data?.divisions,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get division fail");
    return rejectWithValue(error);
  }
});

// get all by filter
export const getAllDivisionByFilterAsync = createAsyncThunk<
  GetCommonData<DivisionData>,
  Params<DivisionData>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-geo/getAllDivisionByFilter",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.division}/data`, {
        params,
      });

      return {
        data: data.data?.divisions,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get division fail");
      return rejectWithValue(error);
    }
  }
);

// update
export const updateDivisionAsync = createAsyncThunk<
  null,
  PostRequest<DivisionData, GetSingleDataParams>
>(
  "admin-geo/updateDivision",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.division}/${id}`, data);

      reRender && reRender(true);
      toast("success", "Division update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Division update fail");
      return rejectWithValue(error);
    }
  }
);

// delete
export const deleteDivisionAsync = createAsyncThunk<
  null,
  Params<GetSingleDataParams>
>("admin-geo/deleteDivision", async ({ params }, { rejectWithValue }) => {
  try {
    await AXIOS.delete(`${BASE_API.division}/${params?.id}`);

    params?.reRender && params?.reRender(true);
    toast("success", "Division delete successfully");
    return null;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", "Division delete fail");
    return rejectWithValue(error);
  }
});

// single
export const singDivisionAsync = createAsyncThunk<
  any,
  Params<GetSingleDataParams>
>("admin-geo/singDivisionData", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.division}/${params?.id}`);

    return data.data?.divisions as any;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Single Division data get fail");
    return rejectWithValue(error);
  }
});
