import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../../helpers/api";
import { BASE_API } from "../../../../helpers/apiUrl";
import { toast } from "../../../../helpers/toast";
import {
  CommonOthers,
  CommonParams,
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
  PostRequest,
} from "../../../../types/redux";
import { BankData } from "../../../../types/redux/collateral/bank";

// create
export const createBankAsync = createAsyncThunk<
  null,
  PostRequest<BankData, CommonOthers>
>(
  "admin-collateral/createBank",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.bank, data);

      reRender(true);
      toast("success", "Bank create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Bank create fail");
      return rejectWithValue(error);
    }
  }
);

// get all
export const getAllBankAsync = createAsyncThunk<
  GetCommonData<BankData>,
  Params<CommonParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-collateral/getAllBank", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.bank}/data`, {
      params,
    });

    return {
      data: data.data?.Banks,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get Bank fail");
    return rejectWithValue(error);
  }
});

// get all by filter
export const getAllBankByFilterAsync = createAsyncThunk<
  GetCommonData<BankData>,
  Params<any>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-collateral/getAllBankByFilter",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.bank}/data`, {
        params,
      });

      return {
        data: data.data?.Banks,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get Bank fail");
      return rejectWithValue(error);
    }
  }
);

// update
export const updateBankAsync = createAsyncThunk<
  null,
  PostRequest<BankData, GetSingleDataParams>
>(
  "admin-collateral/updateBank",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.bank}/${id}`, data);

      reRender && reRender(true);
      toast("success", "Bank update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Bank update fail");
      return rejectWithValue(error);
    }
  }
);

// delete
export const deleteBankAsync = createAsyncThunk<
  null,
  Params<GetSingleDataParams>
>("admin-collateral/deleteBank", async ({ params }, { rejectWithValue }) => {
  try {
    await AXIOS.delete(`${BASE_API.bank}/${params?.id}`);

    params?.reRender && params?.reRender(true);
    toast("success", "Bank delete successfully");
    return null;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", "Bank delete fail");
    return rejectWithValue(error);
  }
});

// single
export const singBankAsync = createAsyncThunk<any, Params<GetSingleDataParams>>(
  "admin-collateral/singBankData",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.bank}/${params?.id}`);

      return data.data?.Banks as any;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Single Bank data get fail");
      return rejectWithValue(error);
    }
  }
);
