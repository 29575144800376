import { createSlice } from "@reduxjs/toolkit";
import { DivisionInitialStatus } from "../../../types/redux/geo/division";
import {
  createDivisionAsync,
  deleteDivisionAsync,
  getAllDivisionAsync,
  getAllDivisionByFilterAsync,
  singDivisionAsync,
  updateDivisionAsync,
} from "./DvisionApi";

const initialState: DivisionInitialStatus<any, any> = {
  loading: false,
  manageLoading: false,
  division: [],
  divisionByFilter: [],
  singleDivisionData: null,
  countDivision: 0,
};

const divisionSlice = createSlice({
  name: "admin-division",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create
    builder
      .addCase(createDivisionAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(createDivisionAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(createDivisionAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // get all
    builder
      .addCase(getAllDivisionAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllDivisionAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.division = action.payload.data;
        state.countDivision = action.payload.length;
      })
      .addCase(getAllDivisionAsync.rejected, (state) => {
        state.loading = false;
        state.division = [];
        state.countDivision = 0;
      });

    // get all by filter
    builder
      .addCase(getAllDivisionByFilterAsync.pending, (state) => {})
      .addCase(getAllDivisionByFilterAsync.fulfilled, (state, action) => {
        state.division = action.payload.data;
      })
      .addCase(getAllDivisionByFilterAsync.rejected, (state) => {
        state.division = [];
        state.countDivision = 0;
      });

    // update
    builder
      .addCase(updateDivisionAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(updateDivisionAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(updateDivisionAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // delete
    builder
      .addCase(deleteDivisionAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(deleteDivisionAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(deleteDivisionAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // single
    builder
      .addCase(singDivisionAsync.pending, (state) => {
        state.manageLoading = true;
        state.singleDivisionData = null;
      })
      .addCase(singDivisionAsync.fulfilled, (state, action) => {
        state.manageLoading = false;
        state.singleDivisionData = action.payload;
      })
      .addCase(singDivisionAsync.rejected, (state) => {
        state.manageLoading = false;
        state.singleDivisionData = null;
      });
  },
});

export default divisionSlice;
