import { createSlice } from "@reduxjs/toolkit";
import {
  GetWearHouseData,
  WearHouseInitialStatus,
} from "../../../types/redux/wearHouse/wearHouse";
import {
  createWearHouseAsync,
  deleteWearHouseAsync,
  getAllWearHouseAsync,
  getAllWearHouseByFilterAsync,
  singWearHouseAsync,
  updateWearHouseAsync,
} from "./WearHouseApi";

const initialState: WearHouseInitialStatus<GetWearHouseData, any> = {
  loading: false,
  manageLoading: false,
  wearHouses: [],
  wearHouseByFilter: [],
  singleWearHouseData: null,
  countWearHouse: 0,
};

const wearHouseSlice = createSlice({
  name: "admin-wearHouse",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create
    builder
      .addCase(createWearHouseAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(createWearHouseAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(createWearHouseAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // get all
    builder
      .addCase(getAllWearHouseAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllWearHouseAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.wearHouses = action.payload.data;
        state.countWearHouse = action.payload.length;
      })
      .addCase(getAllWearHouseAsync.rejected, (state) => {
        state.loading = false;
        state.wearHouses = [];
        state.countWearHouse = 0;
      });

    // get all by filter
    builder
      .addCase(getAllWearHouseByFilterAsync.pending, (state) => {})
      .addCase(getAllWearHouseByFilterAsync.fulfilled, (state, action) => {
        state.wearHouses = action.payload.data;
      })
      .addCase(getAllWearHouseByFilterAsync.rejected, (state) => {
        state.wearHouses = [];
        state.countWearHouse = 0;
      });

    // update
    builder
      .addCase(updateWearHouseAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(updateWearHouseAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(updateWearHouseAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // delete
    builder
      .addCase(deleteWearHouseAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(deleteWearHouseAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(deleteWearHouseAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // single
    builder
      .addCase(singWearHouseAsync.pending, (state) => {
        state.manageLoading = true;
        state.singleWearHouseData = null;
      })
      .addCase(singWearHouseAsync.fulfilled, (state, action) => {
        state.manageLoading = false;
        state.singleWearHouseData = action.payload;
      })
      .addCase(singWearHouseAsync.rejected, (state) => {
        state.manageLoading = false;
        state.singleWearHouseData = null;
      });
  },
});

export default wearHouseSlice;
