import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CommonOthers,
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
  PostRequest,
} from "../../../types/redux";
import {
  ProductData,
  ProductDataParams,
} from "../../../types/redux/manageProduct/product";

// create
export const createProductAsync = createAsyncThunk<
  null,
  PostRequest<Omit<ProductData, "_id">, CommonOthers>
>(
  "admin-product/createProduct",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(`${BASE_API.product}/create`, data);

      reRender(true);
      toast("success", "Product create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Product create fail");
      return rejectWithValue(error);
    }
  }
);

// get all
export const getAllProductAsync = createAsyncThunk<
  GetCommonData<ProductData>,
  Params<ProductDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-product/getAllProduct", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.product}/data`, {
      params,
    });

    return {
      data: data.data?.products,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get Product fail");
    return rejectWithValue(error);
  }
});

// get all by filter
export const getAllProductByFilterAsync = createAsyncThunk<
  GetCommonData<ProductData>,
  Params<ProductDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-product/getAllProductByFilter",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.product}/data`, {
        params,
      });

      return {
        data: data.data?.products,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get Product fail");
      return rejectWithValue(error);
    }
  }
);

// update
export const updateProductAsync = createAsyncThunk<
  null,
  PostRequest<ProductData, GetSingleDataParams>
>(
  "admin-product/updateProduct",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.product}/${id}`, data);

      reRender && reRender(true);
      toast("success", "Product update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Product update fail");
      return rejectWithValue(error);
    }
  }
);

// delete
export const deleteProductAsync = createAsyncThunk<
  null,
  Params<GetSingleDataParams>
>("admin-product/deleteProduct", async ({ params }, { rejectWithValue }) => {
  try {
    await AXIOS.delete(`${BASE_API.product}/${params?.id}`);

    params?.reRender && params?.reRender(true);
    toast("success", "Product delete successfully");
    return null;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", "Product delete fail");
    return rejectWithValue(error);
  }
});

// single
export const singProductAsync = createAsyncThunk<
  any,
  Params<GetSingleDataParams>
>("admin-product/singProductData", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.product}/${params?.id}`);

    return data.data?.products as any;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Single Product data get fail");
    return rejectWithValue(error);
  }
});
