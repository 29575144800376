export default function UserIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M0.905273 19.044C1.82736 17.448 3.15322 16.1227 4.74966 15.2013C6.34611 14.28 8.15691 13.7949 10.0002 13.7949C11.8434 13.7949 13.6542 14.28 15.2506 15.2014C16.8471 16.1229 18.1729 17.4482 19.0949 19.0442M16.7501 5.54492V6.66992M16.7501 5.54492C17.5785 5.54492 18.2501 4.87335 18.2501 4.04492C18.2501 3.21649 17.5785 2.54492 16.7501 2.54492M16.7501 5.54492C15.9216 5.54492 15.2501 4.87335 15.2501 4.04492C15.2501 3.21649 15.9216 2.54492 16.7501 2.54492M16.7501 2.54492V1.41992M18.0491 4.79492L19.0234 5.35742M15.451 4.79492L14.4767 5.35742M18.0491 3.29492L19.0234 2.73242M15.451 3.29492L14.4767 2.73242M15.4963 10.2052C15.0474 11.2267 14.3225 12.1027 13.403 12.7348C12.4835 13.3669 11.4059 13.7299 10.2914 13.7831C9.17688 13.8363 8.06962 13.5775 7.09412 13.0358C6.11862 12.4942 5.31356 11.6912 4.76946 10.717C4.22536 9.74289 3.96379 8.63628 4.01417 7.52163C4.06454 6.40698 4.42487 5.32849 5.05462 4.4074C5.68438 3.48632 6.55859 2.75918 7.57897 2.30774C8.59936 1.85629 9.72544 1.69844 10.8306 1.85194"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
