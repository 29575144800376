import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CommonOthers,
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
  PostRequest,
} from "../../../types/redux";
import {
  AppSliderDataParams,
  CreateSliderData,
  GetSliderData,
} from "../../../types/redux/slider/appSlider";

// create
export const createAppSliderAsync = createAsyncThunk<
  null,
  PostRequest<CreateSliderData, CommonOthers>
>(
  "admin-slider/createAppSlider",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.slider, data);

      reRender(true);
      toast("success", "Slide create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Slide create fail");
      return rejectWithValue(error);
    }
  }
);

// get all
export const getAllAppSliderAsync = createAsyncThunk<
  GetCommonData<GetSliderData>,
  Params<AppSliderDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-slider/getAllAppSlider", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.slider}/data`, {
      params,
    });

    return {
      data: data.data?.slides,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get Slide fail");
    return rejectWithValue(error);
  }
});

// update
export const updateAppSliderAsync = createAsyncThunk<
  null,
  PostRequest<GetSliderData, GetSingleDataParams>
>(
  "admin-slider/updateAppSlider",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.slider}/${id}`, data);

      reRender && reRender(true);
      toast("success", "Slide update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Slide update fail");
      return rejectWithValue(error);
    }
  }
);

// delete
export const deleteAppSliderAsync = createAsyncThunk<
  null,
  Params<GetSingleDataParams>
>("admin-slider/deleteAppSlider", async ({ params }, { rejectWithValue }) => {
  try {
    await AXIOS.delete(`${BASE_API.slider}/${params?.id}`);

    params?.reRender && params?.reRender(true);
    toast("success", "Slide delete successfully");
    return null;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", "Slide delete fail");
    return rejectWithValue(error);
  }
});

// single
export const singAppSliderAsync = createAsyncThunk<
  any,
  Params<GetSingleDataParams>
>("admin-slider/singAppSliderData", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.slider}/${params?.id}`);

    return data.data?.slides as any;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Single slide data get fail");
    return rejectWithValue(error);
  }
});
