import { createSlice } from "@reduxjs/toolkit";

import {
  AppSliderInitialStatus,
  GetSliderData,
} from "../../../types/redux/slider/appSlider";
import {
  createAppSliderAsync,
  deleteAppSliderAsync,
  getAllAppSliderAsync,
  singAppSliderAsync,
  updateAppSliderAsync,
} from "./AppSliderApi";

const initialState: AppSliderInitialStatus<GetSliderData> = {
  loading: false,
  manageLoading: false,
  sliders: [],
  singleSliderData: null,
  countSlider: 0,
};

const appSliderSlice = createSlice({
  name: "admin-slider",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create
    builder
      .addCase(createAppSliderAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(createAppSliderAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(createAppSliderAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // get all
    builder
      .addCase(getAllAppSliderAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllAppSliderAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.sliders = action.payload.data;
        state.countSlider = action.payload.length;
      })
      .addCase(getAllAppSliderAsync.rejected, (state) => {
        state.loading = false;
        state.sliders = [];
        state.countSlider = 0;
      });

    // update
    builder
      .addCase(updateAppSliderAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(updateAppSliderAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(updateAppSliderAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // delete
    builder
      .addCase(deleteAppSliderAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(deleteAppSliderAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(deleteAppSliderAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // single
    builder
      .addCase(singAppSliderAsync.pending, (state) => {
        state.manageLoading = true;
        state.singleSliderData = null;
      })
      .addCase(singAppSliderAsync.fulfilled, (state, action) => {
        state.manageLoading = false;
        state.singleSliderData = action.payload;
      })
      .addCase(singAppSliderAsync.rejected, (state) => {
        state.manageLoading = false;
        state.singleSliderData = null;
      });
  },
});

export default appSliderSlice;
