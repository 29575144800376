import { createSlice } from "@reduxjs/toolkit";
import { ChequeInitialStatus } from "../../../../types/redux/collateral/cheque";
import {
  createChequeAsync,
  deleteChequeAsync,
  getAllChequeAsync,
  getAllChequeByFilterAsync,
  singChequeAsync,
  updateChequeAsync,
} from "./ChequeApi";

const initialState: ChequeInitialStatus<any, any> = {
  loading: false,
  manageLoading: false,
  cheque: [],
  chequeByFilter: [],
  singleChequeData: null,
  countCheque: 0,
};

const chequeSlice = createSlice({
  name: "admin-cheque",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create
    builder
      .addCase(createChequeAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(createChequeAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(createChequeAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // get all
    builder
      .addCase(getAllChequeAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllChequeAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.cheque = action.payload.data;
        state.countCheque = action.payload.length;
      })
      .addCase(getAllChequeAsync.rejected, (state) => {
        state.loading = false;
        state.cheque = [];
        state.countCheque = 0;
      });

    // get all by filter
    builder
      .addCase(getAllChequeByFilterAsync.pending, (state) => {})
      .addCase(getAllChequeByFilterAsync.fulfilled, (state, action) => {
        state.cheque = action.payload.data;
      })
      .addCase(getAllChequeByFilterAsync.rejected, (state) => {
        state.cheque = [];
        state.countCheque = 0;
      });

    // update
    builder
      .addCase(updateChequeAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(updateChequeAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(updateChequeAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // delete
    builder
      .addCase(deleteChequeAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(deleteChequeAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(deleteChequeAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // single
    builder
      .addCase(singChequeAsync.pending, (state) => {
        state.manageLoading = true;
        state.singleChequeData = null;
      })
      .addCase(singChequeAsync.fulfilled, (state, action) => {
        state.manageLoading = false;
        state.singleChequeData = action.payload;
      })
      .addCase(singChequeAsync.rejected, (state) => {
        state.manageLoading = false;
        state.singleChequeData = null;
      });
  },
});

export default chequeSlice;
