import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CommonOthers,
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
  PostRequest,
} from "../../../types/redux";
import {
  CreateWearHouseData,
  GetWearHouseData,
  WearHouseParams,
} from "../../../types/redux/wearHouse/wearHouse";

// create
export const createWearHouseAsync = createAsyncThunk<
  null,
  PostRequest<CreateWearHouseData, CommonOthers>
>(
  "admin-wear-house/createWearHouse",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(`${BASE_API.wearHouse}/create`, data);

      reRender(true);
      toast("success", "Warehouse update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Wear House create fail");
      return rejectWithValue(error);
    }
  }
);

// get all
export const getAllWearHouseAsync = createAsyncThunk<
  GetCommonData<GetWearHouseData>,
  Params<WearHouseParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-wear-house/getAllWearHouse",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.wearHouse}/data`, {
        params,
      });

      return {
        data: data?.data?.warehouses,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get Wear House fail");
      return rejectWithValue(error);
    }
  }
);

// get all by filter
export const getAllWearHouseByFilterAsync = createAsyncThunk<
  GetCommonData<GetWearHouseData>,
  Params<WearHouseParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-wear-house/getAllWearHouseByFilter",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(BASE_API.wearHouse, {
        params,
      });

      return {
        data: data?.data?.warehouses,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get Wear House fail");
      return rejectWithValue(error);
    }
  }
);

// update
export const updateWearHouseAsync = createAsyncThunk<
  null,
  PostRequest<CreateWearHouseData, GetSingleDataParams>
>(
  "admin-wear-house/updateWearHouse",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.wearHouse}/${id}`, data);

      reRender && reRender(true);
      toast("success", "Wear House update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Wear House update fail");
      return rejectWithValue(error);
    }
  }
);

// delete
export const deleteWearHouseAsync = createAsyncThunk<
  null,
  Params<GetSingleDataParams>
>(
  "admin-wear-house/deleteWearHouse",
  async ({ params }, { rejectWithValue }) => {
    try {
      await AXIOS.delete(`${BASE_API.wearHouse}/${params?.id}`);

      params?.reRender && params?.reRender(true);
      toast("success", "Wear House delete successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", "Wear House delete fail");
      return rejectWithValue(error);
    }
  }
);

// single
export const singWearHouseAsync = createAsyncThunk<
  any,
  Params<GetSingleDataParams>
>(
  "admin-wear-house/singGetWearHouseData",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.wearHouse}/${params?.id}`);

      return data?.data?.warehouses as any;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Single Wear House data get fail");
      return rejectWithValue(error);
    }
  }
);
