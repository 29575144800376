import { createSlice } from "@reduxjs/toolkit";
import { BankInitialStatus } from "../../../../types/redux/collateral/bank";
import {
  createBankAsync,
  deleteBankAsync,
  getAllBankAsync,
  getAllBankByFilterAsync,
  singBankAsync,
  updateBankAsync,
} from "./BankApi";

const initialState: BankInitialStatus<any, any> = {
  loading: false,
  manageLoading: false,
  BankItem: [],
  BankItemByFilter: [],
  singleBankItemData: null,
  countBankItem: 0,
};

const bankSlice = createSlice({
  name: "admin-collateral",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create
    builder
      .addCase(createBankAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(createBankAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(createBankAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // get all
    builder
      .addCase(getAllBankAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllBankAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.BankItem = action.payload.data;
        state.countBankItem = action.payload.length;
      })
      .addCase(getAllBankAsync.rejected, (state) => {
        state.loading = false;
        state.BankItem = [];
        state.countBankItem = 0;
      });

    // get all by filter
    builder
      .addCase(getAllBankByFilterAsync.pending, (state) => {})
      .addCase(getAllBankByFilterAsync.fulfilled, (state, action) => {
        state.BankItem = action.payload.data;
      })
      .addCase(getAllBankByFilterAsync.rejected, (state) => {
        state.BankItem = [];
        state.countBankItem = 0;
      });

    // update
    builder
      .addCase(updateBankAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(updateBankAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(updateBankAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // delete
    builder
      .addCase(deleteBankAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(deleteBankAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(deleteBankAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // single
    builder
      .addCase(singBankAsync.pending, (state) => {
        state.manageLoading = true;
        state.singleBankItemData = null;
      })
      .addCase(singBankAsync.fulfilled, (state, action) => {
        state.manageLoading = false;
        state.singleBankItemData = action.payload;
      })
      .addCase(singBankAsync.rejected, (state) => {
        state.manageLoading = false;
        state.singleBankItemData = null;
      });
  },
});

export default bankSlice;
