export default function WearHouseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
    >
      <path
        d="M1.25 18.75H20.75M4.25 18.75V1.5C4.25 1.30109 4.32902 1.11032 4.46967 0.96967C4.61032 0.829018 4.80109 0.75 5 0.75H17C17.1989 0.75 17.3897 0.829018 17.5303 0.96967C17.671 1.11032 17.75 1.30109 17.75 1.5V18.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.625 10.875C14.2463 10.875 14.75 10.3713 14.75 9.75C14.75 9.12868 14.2463 8.625 13.625 8.625C13.0037 8.625 12.5 9.12868 12.5 9.75C12.5 10.3713 13.0037 10.875 13.625 10.875Z"
        fill="currentColor"
      />
    </svg>
  );
}
