import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CommonOthers,
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
  PostRequest,
} from "../../../types/redux";
import {
  BankListData,
  BankListParams,
} from "../../../types/redux/bank/bankList";

// create
export const createBankListAsync = createAsyncThunk<
  null,
  PostRequest<BankListData, CommonOthers>
>(
  "admin-bank/createBankList",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(`${BASE_API.bankList}/create`, data);
      reRender(true);
      toast("success", "Bank create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Bank create fail");
      return rejectWithValue(error);
    }
  }
);

// get all
export const getAllBankListAsync = createAsyncThunk<
  GetCommonData<BankListData>,
  Params<BankListParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-bank/getAllBankList", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.bankList}/data`, {
      params,
    });

    return {
      data: data.data?.banks,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get Bank fail");
    return rejectWithValue(error);
  }
});

// get all by filter
export const getAllBankListByFilterAsync = createAsyncThunk<
  GetCommonData<BankListData>,
  Params<any>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-bank/getAllBankListByFilter",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.bankList}/data`, {
        params,
      });

      return {
        data: data.data?.banks,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get Bank fail");
      return rejectWithValue(error);
    }
  }
);

// update
export const updateBankListAsync = createAsyncThunk<
  null,
  PostRequest<BankListData, GetSingleDataParams>
>(
  "admin-bank/updateBankList",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.bankList}/${id}`, data);

      reRender && reRender(true);
      toast("success", "Bank update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Bank update fail");
      return rejectWithValue(error);
    }
  }
);

// delete
export const deleteBankListAsync = createAsyncThunk<
  null,
  Params<GetSingleDataParams>
>("admin-bank/deleteBankList", async ({ params }, { rejectWithValue }) => {
  try {
    await AXIOS.delete(`${BASE_API.bankList}/${params?.id}`);

    params?.reRender && params?.reRender(true);
    toast("success", "Bank delete successfully");
    return null;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", "Bank delete fail");
    return rejectWithValue(error);
  }
});

// single
export const singBankListAsync = createAsyncThunk<
  any,
  Params<GetSingleDataParams>
>("admin-bank/singBankList", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.bankList}/${params?.id}`);

    return data.data?.banks as any;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Single Bank data get fail");
    return rejectWithValue(error);
  }
});
