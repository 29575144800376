import { createSlice } from "@reduxjs/toolkit";
import { SbuInitialStatus } from "../../../types/redux/sbu/sbu";
import { getAllSbuAsync } from "./SbuApi";

const initialState: SbuInitialStatus<any> = {
  loading: false,
  manageLoading: false,
  sbuData: [],
  singleSbuDataData: null,
  countSbuData: 0,
};

const sbuSlice = createSlice({
  name: "admin-sbu",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get all
    builder
      .addCase(getAllSbuAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllSbuAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.sbuData = action.payload.data;
        state.countSbuData = action.payload.length;
      })
      .addCase(getAllSbuAsync.rejected, (state) => {
        state.loading = false;
        state.sbuData = [];
        state.countSbuData = 0;
      });
  },
});

export default sbuSlice;
