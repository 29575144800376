import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import {
  GiftItemData,
  GiftItemParams,
} from "../../../../types/redux/gift/giftItem";

import AXIOS from "../../../../helpers/api";
import {
  CommonOthers,
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
  PostRequest,
} from "../../../../types/redux";

import { BASE_API } from "../../../../helpers/apiUrl";
import { toast } from "../../../../helpers/toast";

// create
export const createGiftItemAsync = createAsyncThunk<
  null,
  PostRequest<GiftItemData, CommonOthers>
>(
  "admin-gift/createGiftItem",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(`${BASE_API.giftItem}/create`, data);

      reRender(true);
      toast("success", "GiftItem create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "GiftItem create fail");
      return rejectWithValue(error);
    }
  }
);

// get all
export const getAllGiftItemAsync = createAsyncThunk<
  GetCommonData<GiftItemData>,
  Params<GiftItemParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-gift/getAllGiftItem", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.giftItem}/data`, {
      params,
    });

    return {
      data: data.data?.giftItems,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get GiftItem fail");
    return rejectWithValue(error);
  }
});

// get all by filter
export const getAllGiftItemByFilterAsync = createAsyncThunk<
  GetCommonData<GiftItemData>,
  Params<any>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-gift/getAllGiftItemByFilter",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.giftItem}/data`, {
        params,
      });

      return {
        data: data.data?.giftItems,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get GiftItem fail");
      return rejectWithValue(error);
    }
  }
);

// update
export const updateGiftItemAsync = createAsyncThunk<
  null,
  PostRequest<GiftItemData, GetSingleDataParams>
>(
  "admin-gift/updateGiftItem",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.giftItem}/${id}`, data);

      reRender && reRender(true);
      toast("success", "GiftItem update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "GiftItem update fail");
      return rejectWithValue(error);
    }
  }
);

// delete
export const deleteGiftItemAsync = createAsyncThunk<
  null,
  Params<GetSingleDataParams>
>("admin-gift/deleteGiftItem", async ({ params }, { rejectWithValue }) => {
  try {
    await AXIOS.delete(`${BASE_API.giftItem}/${params?.id}`);

    params?.reRender && params?.reRender(true);
    toast("success", "Gift item delete successfully");
    return null;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", "Gift item delete fail");
    return rejectWithValue(error);
  }
});

// single
export const singGiftItemAsync = createAsyncThunk<
  any,
  Params<GetSingleDataParams>
>("admin-gift/singGiftItemData", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.giftItem}/${params?.id}`);

    return data.data?.giftItems as any;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Single gift item data get fail");
    return rejectWithValue(error);
  }
});
