import { createSlice } from "@reduxjs/toolkit";
import {
  UnitData,
  UnitInitialStatus,
} from "../../../types/redux/manageProduct/unit";
import {
  createUnitAsync,
  deleteUnitAsync,
  getAllUnitAsync,
  getAllUnitByFilterAsync,
  singUnitAsync,
  updateUnitAsync,
} from "./UnitApi";

const initialState: UnitInitialStatus<UnitData, any> = {
  loading: false,
  manageLoading: false,
  units: [],
  unitByFilter: [],
  singleUnitData: null,
  countUnit: 0,
};

const unitSlice = createSlice({
  name: "admin-unit",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create
    builder
      .addCase(createUnitAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(createUnitAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(createUnitAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // get all
    builder
      .addCase(getAllUnitAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllUnitAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.units = action.payload.data;
        state.countUnit = action.payload.length;
      })
      .addCase(getAllUnitAsync.rejected, (state) => {
        state.loading = false;
        state.units = [];
        state.countUnit = 0;
      });

    // get all by filter
    builder
      .addCase(getAllUnitByFilterAsync.pending, (state) => {})
      .addCase(getAllUnitByFilterAsync.fulfilled, (state, action) => {
        state.units = action.payload.data;
      })
      .addCase(getAllUnitByFilterAsync.rejected, (state) => {
        state.units = [];
        state.countUnit = 0;
      });

    // update
    builder
      .addCase(updateUnitAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(updateUnitAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(updateUnitAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // delete
    builder
      .addCase(deleteUnitAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(deleteUnitAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(deleteUnitAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // single
    builder
      .addCase(singUnitAsync.pending, (state) => {
        state.manageLoading = true;
        state.singleUnitData = null;
      })
      .addCase(singUnitAsync.fulfilled, (state, action) => {
        state.manageLoading = false;
        state.singleUnitData = action.payload;
      })
      .addCase(singUnitAsync.rejected, (state) => {
        state.manageLoading = false;
        state.singleUnitData = null;
      });
  },
});

export default unitSlice;
